import React, { useContext } from "react"
import Layout from "../layouts/SignLayout"
import { graphql } from "gatsby"
import { Row, Col, Container } from 'react-bootstrap'
import {
  Link,
  navigate
} from "gatsby"
import fb from '../../static/images/social/icon_fb.png'
import ins from '../../static/images/social/icon_ins.png'
import ytb from '../../static/images/social/icon_ytb.png'
import parse from "html-react-parser"
import BannerNews from "../components/BannerNewsRoom/BannerNews"
import CountryContext from "../context/CountryContext";
import DriverSignUp from "../containers/DriverSignUp/DriverSignUp"
import { injectIntl } from "gatsby-plugin-intl"
import { ChevronLeft } from 'react-bootstrap-icons';

import './post.scss'

const BlogPostTemplate = ({data: { previous, next, post, newPost }, intl, ...props}) => {
  const languageCode = intl.locale || "en"
  const Context = useContext(CountryContext)
  const backNavigation = () => {
    navigate(-1)
  }
  return (
    <Layout>
      <BannerNews 
        fleetInfo={Context && Context.fleetInfo && Context.fleetInfo.fleet|| {}}
      />
      <Container>
        <Row className = "postContent" >
          <Col xs={12} md={8} className="postContent_left">
            <div className="postContent__postTitle">
              <h1>{!!post.title && parse(post.title)}</h1>
              <p>{!!post.date && parse(post.date)}</p>
            </div>
            {
              !!post.content && parse(post.content)
            }
            {/* <Link className="backNewsroom" to={`/${languageCode}/blog-ms`} rel="prev">
               <ChevronLeft /> 
               {intl.formatMessage({id: "newsroom.lastPage"})}
            </Link> */}
            <span className="backNewsroom" onClick={backNavigation}>
               <ChevronLeft /> 
               {intl.formatMessage({id: "newsroom.lastPage"})}
            </span>
          </Col>
          <Col xs={12} md={4}>
            <div className='right-social'>
              {
                intl.locale == "my-ms"
                ? <p>Mengikuti <span className="logo_text">GOJO</span> dalam:</p>
                : intl.locale == "my-zh"
                  ? <h1>追随 <span className="logo_text">GOJO</span> 社交网</h1>
                  : <p>Follow <span className="logo_text">GOJO</span> on:</p>
              }
              <div>
                <a href="https://www.facebook.com/gojomalaysia/" target="_blank">
                  <img src={fb}/>
                </a>
                <a href="https://www.instagram.com/gojomalaysia/" target="_blank">
                  <img src={ins}/>
                </a>
                <a href="https://www.youtube.com/gojomalaysia/" target="_blank">
                  <img src={ytb}/>
                </a>
              </div>
            </div>
            <div className="newsPost">
              {
                !!newPost && newPost.nodes.map((post) => {
                  let urlBanner = post.featuredImage && post.featuredImage.node && post.featuredImage.node.sourceUrl || ''
                  return (
                    <div className="newsPost__content">
                      <div className="newsPost__content__thumbnail">
                        <Link to={`/${languageCode}${post.uri}`}>
                          {urlBanner && <img src={urlBanner} />}
                        </Link>
                      </div>
                      <p className="date">{post.date}</p>
                      <Link to={`/${languageCode}${post.uri}`}>
                        <h3>{post.title}</h3>
                      </Link>
                    </div>
                  )
                })
              }
            </div>
          </Col>
        </Row>
        <Row>
          <nav className="blog-post-nav">
            <ul
              style={{
                display: `flex`,
                flexWrap: `wrap`,
                justifyContent: `space-between`,
                listStyle: `none`,
                padding: 0,
              }}
            >
               {/* <li>
                  <Link to={'/blog-ms'} rel="prev"> <ChevronUp /> Last page </Link>
              </li> */}
              {/* <li>
                {previous && (
                  <Link to={previous.uri} rel="prev">
                    ← {parse(previous.title)}
                  </Link>
                )}
              </li>

              <li>
                {next && (
                  <Link to={next.uri} rel="next">
                    {parse(next.title)} →
                  </Link>
                )}
              </li> */}
            </ul>
          </nav>
        </Row>
      </Container>
      <DriverSignUp />
    </Layout>
  )
}

export const query = graphql`
  query BlogPostById(
    $id: String!
    $previousPostId: String
    $nextPostId: String
    $nameCategories: String
    ) {
      post: wpPost(
        id: { eq: $id }
      ) {
        id
        excerpt
        content
        title
        date(formatString: "MMMM DD, YYYY")
        featuredImage {
          node {
            sourceUrl
          }
        }
        categories {
          nodes {
            name
          }
        }
      }
    previous: wpPost(id: { eq: $previousPostId }) {
      uri
      title
    }
    next: wpPost(id: { eq: $nextPostId }) {
      uri
      title
    }
    newPost: allWpPost(limit: 5, filter: {
        categories: {
          nodes: {
            elemMatch: {
              name: {
                eq: $nameCategories
              }
            }
          }
        }
      }) {
      nodes {
        id
        title
        uri
        featuredImage {
          node {
            sourceUrl
          }
        }
        date(formatString: "MMMM DD, YYYY")
      }
    }
  }
`

export default injectIntl(BlogPostTemplate)